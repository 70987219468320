import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  CardEmail,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import { API } from "aws-amplify"
import { get } from "lodash"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import styled from "styled-components"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loading from "components/Loading"

const InfoText = styled.span`
  font-size: 11px;
  opacity: 0.4;
`
const ErrorText = styled.p`
  color: red;
`

const asyncForEach = async (array, callback) => {
  const result = []
  for (let index = 0; index < (array.length || array.size); index++) {
    result.push(await callback(array[index] || array.docs[index], index, array))
  }
  return result
}

const ProjectsCreate = props => {
  const userId = get(props, "match.params.user_id")
  const [products, setProducts] = useState([])
  const [startDate, setstartDate] = useState(new Date())
  const [endDate, setendDate] = useState(new Date())
  const [selectedImageFiles, setSelectedImageFiles] = useState([])
  const [selectedBasaleFiles, setSelectedBasaleFiles] = useState([])
  const [selectedContentFiles, setSelectedContentFiles] = useState([])
  const [selectedMulti, setSelectedMulti] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [email, setEmail] = useState("")
  const [permalink, setPermalink] = useState("")
  const [tags, setTags] = useState("")
  const [basaleUrl, setBasaleUrl] = useState("")
  const [isEditPermalink, setIsEditPermalink] = useState(false)
  const [createProgress, setCreateProgress] = useState("")
  const [isErrorImageFileSize, setIsErrorImageFileSize] = useState(false)
  const [isImageFileSet, setIsImageFileSet] = useState(false)
  const [currentSelectedImageFile, setCurrentSelectedImageFile] = useState()
  const [isErrorContentFileSize, setIsErrorContentFileSize] = useState(false)
  const [isContentFileSet, setIsContentFileSet] = useState(false)
  const [currentSelectedContentFile, setCurrentSelectedContentFile] = useState()
  const [isErrorBasaleFileSize, setIsErrorBasaleFileSize] = useState(false)
  const [isBasaleFileSet, setIsBasaleFileSet] = useState(false)
  const [currentSelectedBasaleFile, setCurrentSelectedBasaleFile] = useState()

  useEffect(async () => {
    if (userId) {
      setIsFetching(true)
      const apiUrl = "/users/" + userId
      const response = await API.get("Main", apiUrl)
      const data = get(response, "response")
      const { email } = data
      setEmail(email)
      setIsLoading(false)
      setIsFetching(false)
    } else {
      setIsFetching(false)
    }
  }, [userId])

  const createUser = async () => {
    setIsLoading(true)
    if (userId) {
      setCreateProgress("Updating user")
      const createUserApiUrl = "/users/" + userId
      const createResponse = await API.post("Main", createUserApiUrl, {
        body: {
          email,
        },
      })

      setIsLoading(false)
      toastr.success("User updated.")
    } else {
      setCreateProgress("Creating user")
      const createUserApiUrl = "/users"
      const createResponse = await API.post("Main", createUserApiUrl, {
        body: {
          email,
        },
      })

      setIsLoading(false)
      const { history } = props
      history.push("/users")
      toastr.success("User created.")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <email>{userId ? "Edit User" : "Create New User"}</email>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            email="Users"
            back="/users"
            breadcrumbItem={userId ? "Edit User" : "Create New User"}
          />

          <Row>
            <Card>
              <CardBody>
                {isFetching ? (
                  <Loading />
                ) : (
                  <>
                    <Form>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="useremail"
                          className="col-form-label col-lg-2"
                        >
                          Email <span className="text-danger">*</span>
                        </Label>
                        <Col lg="10">
                          <Input
                            disabled={userId || isLoading}
                            onChange={event => {
                              const value = event.target.value
                              setEmail(value)
                            }}
                            value={email}
                            id="useremail"
                            name="useremail"
                            type="text"
                            className="form-control"
                            placeholder="Enter User email..."
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="useremail"
                          className="col-form-label col-lg-2"
                        ></Label>
                        <Col lg="10">
                          <p style={{ color: "#AAAAAA" }}>
                            Created user will recieve an email with temporary
                            password contained. Please login and create new
                            password.
                          </p>
                        </Col>
                      </FormGroup>
                    </Form>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button
                          type="submit"
                          color="primary"
                          onClick={createUser}
                        >
                          {isLoading ? (
                            <>
                              <span>{createProgress}</span>{" "}
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                            </>
                          ) : (
                            <span>{userId ? "Update" : "Create User"}</span>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectsCreate
