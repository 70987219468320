import React, { useState } from "react"
import { API } from "aws-amplify"
import styled from "styled-components"
import MetaTags from "react-meta-tags"
import axios from "axios"
import toastr from "toastr"
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  Progress,
  UncontrolledDropdown
} from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"

const FullWidthButton = styled(Button)`
  width: 100%;
`

const FullWidthBlueButton = styled(FullWidthButton)`
  background: #1291d0;
`

const VerticalCenter = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const Tag = styled.span`
  background: #df057d;
  padding: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: inline-block;
  color: white;
`

const BlueTag = styled(Tag)`
  background: #1291d0;
`

const TagCard = styled(Card)`
  border-top-left-radius: 0px;
`

const TagCardWithResult = styled(TagCard)`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`

const ResultContainer = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #f9f9f9;
  padding: 1.25rem 1.25rem;
  border-top: 1px solid #eeeeee;
`

const UrlResult = styled.a`
  color: #1291d0;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 16px;
`

const CenterCol = styled(Col)`
  display: flex;
  align-items: center;
`

const CenterColCol = styled(CenterCol)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const ClearButton = styled(Button)`
  background: unset;
  border: unset;
`

const InfoText = styled.span`
  color: #888888;
  font-size: 10px;
`
const isUrl = url =>
  !!url.match(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
  )
const Dashboard = props => {
  const [isSingleLoading, setIsSingleLoading] = useState(false)
  const [batchProgress, setBatchProgress] = useState(0)
  const [singleResult, setSingleResult] = useState()
  const [isBatchLoading, setIsBatchLoading] = useState(false)
  const [batchResult, setBatchResult] = useState()
  const [urlInput, setURLInput] = useState("")
  const [urlBatchInput, setURLBatchInput] = useState()
  const [singleError, setSingleError] = useState("")
  const [batchError, setBatchError] = useState("")
  const checkSingleKey = event => {
    if (event.key === "Enter") {
      createSingle()
    }
  }
  const fetchBatchStatus = async id => {
    const response = await API.post("Main", "/check_status", { body: { id } })
    const { count = 100, uploaded_count = 0, error_count = 0 } = response
    const fullCount = 20 + 100
    const fullProgress = 20 + (((uploaded_count + error_count) / count) * 100)

    const currentProgress = parseInt((fullProgress / fullCount) * 100, 10)
    setBatchProgress(currentProgress)
    if (!response.isDone) {
      setTimeout(() => {
        fetchBatchStatus(id)
      }, 4000)
    } else {
      setBatchResult(response)
      setIsBatchLoading(false)
    }
  }

  const createSingle = async () => {
    if (!isUrl(urlInput)) {
      setSingleError("Invalid url format")
      return
    }
    setSingleResult("")
    setSingleError("")
    setIsSingleLoading(true)
    const response = await API.post("Main", "/store", {
      body: { url: urlInput }
    })
    setIsSingleLoading(false)
    setSingleResult(response.url)
  }
  const createBatch = async () => {
    if (urlBatchInput) {
      // if (!urlBatchInput[0] || urlBatchInput[0].type !== "text/csv") {
      //   console.log("2")
      //   console.log("!urlBatchInput[0]", !urlBatchInput[0])
      //   console.log("urlBatchInput[0].type", urlBatchInput[0].type)
      //   setBatchError("Invalid file type. Only support CSV format.")
      //   return
      // }
      const currentFile = urlBatchInput[0]
      const fileName = currentFile.name
      if (!fileName.match(/\.csv$/)) {
        setBatchError("Invalid file type. Only support CSV format.")
        return
      }
      setIsBatchLoading(true)
      setBatchProgress(0)
      setBatchResult()
      const response = await API.post("Main", "/presign_upload_csv", {
        body: { name: fileName }
      })
      const { id, upload_url: uploadUrl, get_url: getImageUrl, acl } = response
      const options = {
        headers: {
          "Content-Type": "text/csv",
          "x-amz-acl": acl
        }
      }
      setBatchProgress(10)
      await axios.put(uploadUrl, currentFile, options)
      setBatchProgress(16)
      setTimeout(() => {
        fetchBatchStatus(id)
      }, 2500)
      // setIsBatchLoading(false)
      // setBatchResult("gas")
    }
  }
  const getDownloadBatch = async id => {
    const response = await API.post("Main", "/get_download_url", {
      body: { id }
    })
    const { url, raw } = response
    const createdDate = new Date(parseInt(raw.created_at, 10))
    const filesize = raw.filesize || ""
    const newName =
      [
        createdDate.getDate(),
        createdDate.getMonth() + 1,
        createdDate.getFullYear()
      ].join("_") +
      "_" +
      [
        createdDate.getHours(),
        createdDate.getMinutes(),
        createdDate.getSeconds()
      ].join("") +
      "_" +
      filesize
    let a = document.createElement("a")
    a.href = url
    a.setAttribute("download", newName + ".csv")
    a.click()
  }

  const SingleElement = singleResult ? TagCardWithResult : TagCard
  const BatchElement = batchResult ? TagCardWithResult : TagCard

  let batchFileName = ""
  if (batchResult) {
    const createdDate = new Date(parseInt(batchResult.created_at, 10))
    const filesize = batchResult.filesize || ""
    const newName =
      [
        createdDate.getDate(),
        createdDate.getMonth() + 1,
        createdDate.getFullYear()
      ].join("_") +
      "_" +
      [
        createdDate.getHours(),
        createdDate.getMinutes(),
        createdDate.getSeconds()
      ].join("") +
      "_" +
      filesize
    batchFileName = newName
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Generate</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <div>
              <div>
                <Tag>Single URL Generation</Tag>
              </div>
              <SingleElement>
                <CardBody>
                  <FormGroup row>
                    <Label
                      htmlFor="userurlInput"
                      className="col-form-label col-lg-2"
                    >
                      URL
                    </Label>
                    <Col lg="8">
                      <Input
                        disabled={isSingleLoading}
                        onChange={event => {
                          const value = event.target.value
                          setURLInput(value)
                          setSingleError("")
                          setSingleResult("")
                        }}
                        onKeyDown={checkSingleKey}
                        value={urlInput}
                        id="userurlInput"
                        name="userurlInput"
                        type="text"
                        className="form-control"
                      />
                      {singleError && (
                        <span className="d-block mt-3 text-danger">
                          {singleError}
                        </span>
                      )}
                    </Col>
                    <Col lg="2">
                      <FullWidthButton
                        type="submit"
                        color="primary"
                        onClick={createSingle}
                        disabled={isSingleLoading}
                      >
                        {isSingleLoading ? (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                          </>
                        ) : (
                          <span>Generate</span>
                        )}
                      </FullWidthButton>
                    </Col>
                  </FormGroup>
                </CardBody>
                {singleResult && (
                  <ResultContainer>
                    <Row>
                      <Col lg="2" />
                      <CenterCol lg="8">
                        <UrlResult href={singleResult}>
                          {singleResult}
                        </UrlResult>
                        <CopyToClipboard
                          text={singleResult}
                          onCopy={() => toastr.success("Copied.")}
                        >
                          <ClearButton color="light">
                            <i className="bx bx-clipboard"></i>
                          </ClearButton>
                        </CopyToClipboard>
                      </CenterCol>
                      <Col lg="2" />
                    </Row>
                  </ResultContainer>
                )}
              </SingleElement>
            </div>
          </Row>
          <Row>
            <div>
              <div>
                <BlueTag>Batch URL Generation</BlueTag>
              </div>
              <BatchElement>
                <CardBody>
                  <FormGroup row>
                    <Label
                      htmlFor="userurlInput"
                      className="col-form-label col-lg-2"
                    >
                      File (CSV)
                    </Label>
                    <Col lg="8">
                      <VerticalCenter>
                        <Input
                          onChange={event => {
                            const gas = event.target.files
                            debugger
                            setBatchError(undefined)
                            setURLBatchInput(event.target.files)
                          }}
                          type="file"
                          disabled={isBatchLoading}
                          name="file"
                          id="exampleFile"
                        />
                      {batchError && (
                        <span className="d-block mt-3 text-danger">
                          {batchError}
                        </span>
                      )}
                      </VerticalCenter>
                    </Col>
                    <Col lg="2">
                      <FullWidthBlueButton
                        disabled={isBatchLoading}
                        type="submit"
                        onClick={createBatch}
                      >
                        {isBatchLoading ? (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                          </>
                        ) : (
                          <span>Generate</span>
                        )}
                      </FullWidthBlueButton>
                    </Col>
                  </FormGroup>
                  {isBatchLoading && (
                    <div className="">
                      <br />
                      <Progress color="secondary" value={batchProgress}>
                        {batchProgress}%
                      </Progress>
                    </div>
                  )}
                </CardBody>
                {batchResult && (
                  <ResultContainer>
                    <Row>
                      <Col lg="2" />
                      <CenterColCol lg="8">
                        <UrlResult
                          onClick={() => {
                            getDownloadBatch(batchResult.id)
                          }}
                        >{`${batchFileName}.csv`}</UrlResult>
                        <div>
                          <InfoText>Total : {batchResult.count}</InfoText>
                          {batchResult.errorCount > 0 && (
                            <InfoText className="text-danger">
                              &nbsp;&nbsp;(Error : {batchResult.error_count})
                            </InfoText>
                          )}
                        </div>
                      </CenterColCol>
                      <Col lg="2">
                        <FullWidthBlueButton
                          color="secondary"
                          onClick={() => {
                            getDownloadBatch(batchResult.id)
                          }}
                        >
                          Download
                        </FullWidthBlueButton>
                      </Col>
                    </Row>
                  </ResultContainer>
                )}
              </BatchElement>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Dashboard
