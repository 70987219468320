import { API } from "aws-amplify"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Loading"
import { get, map } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap"

const UserList = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [users, setUsers] = useState(true)
  useEffect(async () => {
    const apiUrl = "/users"
    const response = await API.get("Main", apiUrl)
    setUsers(response.users)
    setIsLoading(false)
  }, [])

  const updateUser = async (user, flag) => {
    setIsActionLoading(true)
    const updateApiUrl = "/users/" + user.email
    const updateResponse = await API.post("Main", updateApiUrl, { body: { flag } })
    const apiUrl = "/users"
    const response = await API.get("Main", apiUrl)
    setUsers(response.users)
    setIsActionLoading(false)
  }

  const onDisable = user => async () => {
    await updateUser(user, "disable")
  }

  const onEnable = user => async () => {
    await updateUser(user, "enable")
  }

  const onDelete = user => async () => {
    await updateUser(user, "delete")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users List</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Users"
            back="/users"
            breadcrumbItem="Users List"
          />

          <Row>
            <Card>
              <CardBody>
                <Col lg="12" style={{ position: "relative" }}>
                  {isActionLoading && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "black",
                        position: "absolute",
                        opacity: "0.2",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <i
                        className="bx bx-loader bx-spin font-size-20 align-middle me-2"
                        style={{ color: "white" }}
                      />
                    </div>
                  )}
                  <div className="">
                    <div className="table-responsive">
                      <Table className="blog-list-table table-nowrap align-middle table-borderless">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "100px" }}>
                              #
                            </th>
                            <th scope="col">Email</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(users, (user, index) => (
                            <tr key={index}>
                              <td>
                                <img
                                  src={`/default_avatar.png`}
                                  alt=""
                                  className="avatar-sm"
                                />
                              </td>
                              <td>
                                <h5 className="text-truncate font-size-14 mb-0">
                                  {/* <Link
                                    to={`/users/${user.id}`}
                                    className="text-dark hover-primary"
                                  > */}
                                  {user.email}
                                  {/* </Link> */}
                                </h5>
                              </td>
                              <td>
                                <h5
                                  className="text-truncate font-size-11 mb-0"
                                  style={{
                                    color: "white",
                                    backgroundColor: user.is_enabled
                                      ? "#34c38f"
                                      : "#74788d",
                                    textAlign: "center",
                                    width: "90px",
                                    padding: "5px",
                                    borderRadius: "50px",
                                  }}
                                >
                                  {user.is_enabled ? "Enabled" : "Disabled"}
                                </h5>
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    href="#"
                                    className="card-drop"
                                    tag="i"
                                  >
                                    <i className="mdi mdi-dots-horizontal font-size-18" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    {user.is_enabled ? (
                                      <DropdownItem onClick={onDisable(user)}>
                                        Disable
                                      </DropdownItem>
                                    ) : (
                                      <>
                                        <DropdownItem onClick={onEnable(user)}>
                                          Enable
                                        </DropdownItem>
                                        <DropdownItem onClick={onDelete(user)}>
                                          Remove
                                        </DropdownItem>
                                      </>
                                    )}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
                {isLoading && <Loading />}
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserList.propTypes = {}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserList))
