import { select, call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
  FORCE_CHANGE_PASSWORD,
  GET_CURRENT_USER
} from "./actionTypes"
import {
  apiError,
  showChangePassword,
  forceChangePasswordSuccess,
  loginSuccess,
  logoutUserSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import Amplify, { Auth } from "aws-amplify"
import AwsConfig from "awsconfig"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin
} from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()
Amplify.configure(AwsConfig)

const getChangePasswordUser = state => state.Login.change_password_user
const getOldPasswordUser = state => state.Login.change_password_old_password

function* getCurrentUser({ payload: { history } }) {
  try {
    const response = yield call([Auth, "currentAuthenticatedUser"])
    localStorage.setItem("authUser", JSON.stringify(response))
    yield put(loginSuccess(response))
    history.push("/generate")
  } catch(error) {
    if (error === "The user is not authenticated") {
      localStorage.removeItem("authUser")
      history.push("/login")
    }
  }
}

function* forceChangePassword({ payload: { user, history } }) {
  const changePasswordUser = yield select(getChangePasswordUser)
  const oldPasswordUser = yield select(getOldPasswordUser)
  try {
    const response = yield call(
      [Auth, "completeNewPassword"],
      changePasswordUser,
      user.new_password
    )
    localStorage.setItem("authUser", JSON.stringify(response))
    yield put(forceChangePasswordSuccess(response))
    yield put(loginSuccess(response))
    history.push("/generate")
  } catch (error) {
    yield put(apiError(error.message ? error.message : error))
  }
}

function* loginUser({ payload: { user, history } }) {
  let success = false
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
      success = true
    } else if (process.env.REACT_APP_DEFAULTAUTH === "aws") {
      const response = yield call([Auth, "signIn"], user.email, user.password)
      if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
        yield put(showChangePassword(response, user.password))
      } else {
        localStorage.setItem("authUser", JSON.stringify(response))
        yield put(loginSuccess(response))
        success = true
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
      success = true
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
      success = true
    }
    if (success) history.push("/generate")
  } catch (error) {
    yield put(apiError(error.message ? error.message : error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "aws") {
      const response = yield call([Auth, "signOut"])
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(GET_CURRENT_USER, getCurrentUser)
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(FORCE_CHANGE_PASSWORD, forceChangePassword)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
