import React, { useState, useEffect } from "react"
import { API } from "aws-amplify"
import MetaTags from "react-meta-tags"
import styled from "styled-components"
import { Table, Container, Row, Col, Button, Card, CardBody } from "reactstrap"
import _ from "lodash"

const FullWidthButton = styled(Button)`
  width: 100%;
`

const UrlResult = styled.a`
  color: #1291d0;
  text-decoration: underline;
  cursor: pointer;
`

const toIsoString = date => {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num) {
      var norm = Math.floor(Math.abs(num))
      return (norm < 10 ? "0" : "") + norm
    }

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds())
  )
}

const Dashboard = props => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [fromDate, setFromDate] = useState(
    new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7)
      .toISOString()
      .split("T")[0]
  )
  const [toDate, setToDate] = useState(
    new Date().toISOString().split("T")[0]
  )

  const search = async () => {
    setIsLoading(true)
    setData()
    const response = await API.post("Main", "/list_batch", {
      body: {
        sinceTime: String(new Date(fromDate).setHours(0,0,0,0)),
        untilTime: String(new Date(toDate).setHours(23,59,59,999))
      }
    })
    setData(response.result)
    setIsLoading(false)
  }

  useEffect(() => {
    search()
  }, [])
  const getDownloadBatch = async (id, isRaw) => {
    const response = await API.post("Main", "/get_download_url", {
      body: { id, isRaw }
    })
    const { url, raw } = response
    const createdDate = new Date(parseInt(raw.created_at, 10))
    const filesize = raw.filesize || ""
    const newName =
      [
        createdDate.getDate(),
        createdDate.getMonth() + 1,
        createdDate.getFullYear()
      ].join("_") +
      "_" +
      [
        createdDate.getHours(),
        createdDate.getMinutes(),
        createdDate.getSeconds()
      ].join("_") +
      filesize
    let a = document.createElement("a")
    a.href = url
    a.setAttribute("download", newName + ".csv")
    a.click()
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Uploaded report</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg="5">
              <span>From</span>
            </Col>
            <Col lg="5">
              <span>To</span>
            </Col>
            <Col lg="2"></Col>
          </Row>
          <Row className="mt-2">
            <Col lg="5">
              <input
                className="form-control"
                type="date"
                value={fromDate}
                onChange={event => {
                  setFromDate(event.target.value)
                }}
                id="example-datetime-local-input"
              />
            </Col>
            <Col lg="5">
              <input
                className="form-control"
                type="date"
                value={toDate}
                onChange={event => {
                  setToDate(event.target.value)
                }}
                id="example-datetime-local-input"
              />
            </Col>
            <Col lg="2">
              <FullWidthButton
                type="submit"
                color="primary"
                onClick={search}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                  </>
                ) : (
                  <span>Search</span>
                )}
              </FullWidthButton>
            </Col>
          </Row>
          <Card className="mt-4">
            <CardBody>
              {isLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                </div>
              ) : (
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Created date</th>
                        <th>Uploaded File / Full URL</th>
                        <th>Shortlink File / Short URL</th>
                        <th>Total URL</th>
                        <th>Error</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.sortBy(
                        data,
                        item => parseInt(item.created_at) * -1
                      ).map((item, index) => {
                        const rawFile = item.isDone ? (
                          <td>
                            {item.type === "single" ? (
                              <UrlResult href={item.from_url}>
                                {item.from_url || "-"}
                              </UrlResult>
                            ) : (
                              <UrlResult
                                onClick={() => {
                                  getDownloadBatch(item.id, true)
                                }}
                              >
                                {item.name || "-"}
                              </UrlResult>
                            )}
                          </td>
                        ) : (
                          <td>
                            <span>Processing</span>
                          </td>
                        )
                        const newFile = item.isDone ? (
                          <td>
                            {item.type === "single" ? (
                              <UrlResult href={item.to_url}>
                                {item.to_url || "-"}
                              </UrlResult>
                            ) : (
                              <UrlResult
                                onClick={() => {
                                  getDownloadBatch(item.id)
                                }}
                              >
                                Download
                              </UrlResult>
                            )}
                          </td>
                        ) : (
                          <td>
                            <span>Processing</span>
                          </td>
                        )
                        const error =
                          item.error_count > 0 ? (
                            <td>
                              <span className="text-danger">
                                {item.error_count}
                              </span>
                            </td>
                          ) : (
                            <td />
                          )
                        return (
                          <tr>
                            <th scope="row">{index}</th>
                            <td>
                              {new Date(
                                parseInt(item.created_at)
                              ).toLocaleString()}
                            </td>
                            {rawFile}
                            {newFile}
                            <td>{item.count}</td>
                            {error}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
            </CardBody>
          </Card>
          <Row></Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Dashboard
