import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"
import { Row, Col, Alert, Card, CardBody, Container, Modal } from "reactstrap"
import Logo from "../../assets/images/mtl_logo.svg"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { userForgetPassword, resetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"

const ForgetPasswordPage = props => {
  function handleValidSubmit(event, values) {
    props.userForgetPassword(values, props.history)
  }

  function handleValidChangePassword(event, values) {
    props.resetPassword(values, props.history)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Forget Password | Skote - Responsive Bootstrap 5 Admin Dashboard
        </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <Modal isOpen={props.show_reset_password} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Create new password
          </h5>
        </div>
        <div className="modal-body">
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleValidChangePassword(e, v)
            }}
          >
            {props.resetError ? (
              <Alert color="danger">{props.resetError}</Alert>
            ) : null}
            <div className="mb-3">
              <AvField
                name="code"
                label="Code"
                value=""
                className="form-control"
                placeholder="Enter verification code"
                type="text"
                required
              />
              <br />
              <AvField
                name="password"
                label="New password"
                value=""
                className="form-control"
                placeholder="Enter new password"
                type="password"
                required
                errorMessage="Password must be between 8 and 30 characters, at least one uppercase letter, one lowercase letter and one number, one special character."
                validate={{
                  required: { value: true },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&\(\)\[\]\{\}\.\/<>,;:'"\\|\-_+=^#])[A-Za-z0-9@$!%*?&\(\)\[\]\{\}\.\/<>,;:'"\\|\-_+=^#]{8,}$/
                  },
                  minLength: { value: 8 }
                }}
              />
            </div>
            <button
              className="btn btn-primary btn-block waves-effect waves-light w-100"
              type="submit"
            >
              {props.change_password_loading ? (
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
              ) : (
                "Change password"
              )}
            </button>
          </AvForm>
        </div>
      </Modal>
      <div className="account-pages my-5 pt-sm-5">
        <Container fluid>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div
                  className="pt-4 pb-4"
                  style={{
                    backgroundColor: "white!important",
                    borderTop: "10px solid #df057d",
                    borderBottom: "1px solid #ced4d9"
                  }}
                >
                  <Row>
                    <Col xs={7}></Col>
                    <Col className="col-5 align-self-end">
                      <img src={Logo} style={{width: '100%'}} />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-3">
                  <div className="p-2">
                    {props.forgetError && props.forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {props.forgetError}
                      </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-right">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    forgetError,
    forgetSuccessMsg,
    reset_password_loading,
    show_reset_password,
    resetError
  } = state.ForgetPassword
  return {
    forgetError,
    forgetSuccessMsg,
    reset_password_loading,
    show_reset_password,
    resetError
  }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword, resetPassword })(
    ForgetPasswordPage
  )
)
