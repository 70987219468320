import React from "react"
import { Col, Row } from "reactstrap"

const Loading = () => {
  return (
    <Row>
      <Col xs="12">
        <div className="text-center my-3">
          <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
        </div>
      </Col>
    </Row>
  )
}

export default Loading
