var envConfig = {
  API: {
    endpoints: [
      {
        name: "Main",
        endpoint: process.env.REACT_APP_API_URL,
        region: "ap-southeast-1",
      },
    ],
  },
  Auth: {
    region: "ap-southeast-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
}

module.exports = envConfig
