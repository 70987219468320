import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Generate from "../pages/Generate/index"
import History from "../pages/History/index"
import Users from "../pages/Users/index"
import NewUser from "../pages/NewUser/index"

const userRoutes = [
  { path: "/generate", component: Generate },
  { path: "/history", component: History },
  { path: "/users", component: Users },
  { path: "/users/:user_id", component: NewUser },
  { path: "/new-user", component: NewUser },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/generate" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { userRoutes, authRoutes }
