export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"
export const SHOW_CHANGE_PASSWORD = "SHOW_CHANGE_PASSWORD"
export const FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD"
export const FORCE_CHANGE_PASSWORD_SUCCESS = "FORCE_CHANGE_PASSWORD_SUCCESS"
export const GET_CURRENT_USER = "GET_CURRENT_USER"
