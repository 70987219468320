import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect } from "react"

import Logo from '../../assets/images/mtl_logo.svg'
import { Row, Col, CardBody, Card, Alert, Container, Modal } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import {
  loginUser,
  apiError,
  socialLogin,
  forceChangePassword,
} from "../../store/actions"

// import images
import logo from "assets/images/logo.svg"

//Import config
import { facebook, google } from "../../config"

const Login = props => {
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history)
  }

  const handleValidChangePassword = (event, values) => {
    props.forceChangePassword(values, props.history)
  }

  useEffect(() => {
    if (props.user) {
      props.history.push("/generate")
    }
  }, [props.user])
  const getError = () => {
    const currentError = props.error
    if (currentError && typeof currentError === "string") {
      return currentError
    } else if (currentError && currentError instanceof Array) {
      return currentError[1]
    } else {
      return false
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <Modal isOpen={props.need_change_password} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Password change needed
          </h5>
        </div>
        <div className="modal-body">
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleValidChangePassword(e, v)
            }}
          >
            {getError() ? <Alert color="danger">{getError()}</Alert> : null}
            <div className="mb-3">
              <AvField
                name="new_password"
                label="Create new password"
                value=""
                className="form-control"
                placeholder="Enter new password"
                type="password"
                required
                errorMessage="Password must be between 8 and 30 characters, at least one uppercase letter, one lowercase letter and one number, one special character."
                validate={{
                  required: { value: true },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&\(\)\[\]\{\}\.\/<>,;:'"\\|\-_+=^#])[A-Za-z0-9@$!%*?&\(\)\[\]\{\}\.\/<>,;:'"\\|\-_+=^#]{8,}$/,
                  },
                  minLength: { value: 8 },
                }}
              />
            </div>
            <button
              className="btn btn-primary btn-block waves-effect waves-light w-100"
              type="submit"
            >
              {props.change_password_loading ? (
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
              ) : (
                "Change password"
              )}
            </button>
          </AvForm>
        </div>
      </Modal>
      <div className="account-pages my-5 pt-sm-5">
        <Container fluid>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="pt-4 pb-4" style={{backgroundColor: 'white!important', borderTop: '10px solid #df057d', borderBottom: '1px solid #ced4d9'}}>
                  <Row>
                    <Col xs={7}>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={Logo}  style={{width: '100%'}}/>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {/* <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div> */}
                  <br />
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {getError() ? (
                        <Alert color="danger">{getError()}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value=""
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          value=""
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      {false && (
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>
                      )}

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          {props.loading ? (
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                          ) : (
                            "Log In"
                          )}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const {
    error,
    loading,
    need_change_password,
    change_password_user,
    change_password_loading,
    user,
  } = state.Login
  return {
    error,
    loading,
    need_change_password,
    change_password_user,
    change_password_loading,
    user,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    loginUser,
    apiError,
    socialLogin,
    forceChangePassword,
  })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
