import { takeEvery, fork, put, all, call, select } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD, RESET_PASSWORD } from "./actionTypes"
import {
  userResetPasswordError,
  userForgetPasswordSuccess,
  userForgetPasswordError,
  showResetPassword,
} from "./actions"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeForgetPwd,
  postJwtForgetPwd,
} from "../../../helpers/fakebackend_helper"

import Amplify, { Auth } from "aws-amplify"
import AwsConfig from "awsconfig"
Amplify.configure(AwsConfig)
const fireBaseBackend = getFirebaseBackend()

const getResetPasswordEmail = state => state.ForgetPassword.reset_password_email

function* resetUser({ payload: { user, history } }) {
  const { code, password } = user
  const username = yield select(getResetPasswordEmail)
  try {
    const response = yield call(
      [Auth, "forgotPasswordSubmit"],
      username,
      code,
      password
    )
    toastr.success("Password reset.")
    history.push("/login")
  } catch (error) {
    yield put(userResetPasswordError(error.message ? error.message : error))
  }
}

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.forgetPassword, user.email)
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "aws") {
      const response = yield call([Auth, "forgotPassword"], user.email)
      if (response) {
        yield put(showResetPassword(user.email))
        // yield put(
        //   userForgetPasswordSuccess(
        //     "Reset link are sended to your mailbox, check there first"
        //   )
        // )
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtForgetPwd, "/jwt-forget-pwd", {
        email: user.email,
      })
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    } else {
      const response = yield call(postFakeForgetPwd, "/fake-forget-pwd", {
        email: user.email,
      })
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    }
  } catch (error) {
    yield put(userForgetPasswordError(error.message ? error.message : error))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
  yield takeEvery(RESET_PASSWORD, resetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
